import { GetStaticProps, GetStaticPropsContext } from 'next';
import pino from 'pino';

import { registerMetrics } from './registerMetrics';

const logger = pino({ level: process.env.LOG_LEVEL || 'info' });

export const observedGetStaticProps =
  (gSP: GetStaticProps, route: string) =>
  async (context: GetStaticPropsContext) => {
    const start = performance.now();
    const heapStart = process.memoryUsage().heapUsed;

    // Run gSP
    const response = await gSP(context);

    const end = performance.now();
    const heapEnd = process.memoryUsage().heapUsed;

    const duration = (end - start) / 1000;
    const usedMemory =
      Math.round(((heapEnd - heapStart) / 1024 / 1024) * 100) / 100; // MB

    logger.debug(
      `GetStaticProps: [${route}] Duration[${duration}s] Memory[${usedMemory}mb]`,
    );

    registerMetrics({
      route,
      duration,
      usedMemory,
    }).catch(err => {
      logger.error(err, 'Failed to register metrics');
    });

    // Return gSP response result
    return response;
  };
