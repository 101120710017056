import {
  SectionParser,
  useGetJurisdictionListQuery,
  useGetPageQuery,
  useGetLocalesQuery,
  isPopulatedReference,
  PageLayout,
  HeroConnected,
} from '@front/master';
import { Page, Section } from '@shared/master-types';
import React from 'react';

export interface ISectionsPageProps {
  id: string;
  locale: string;
  jurisdiction: string;
  draft: boolean;
}

const SectionsPage = (props: ISectionsPageProps): JSX.Element => {
  const { draft, id, locale, jurisdiction } = props;

  const { data: jurisdictionListObject } = useGetJurisdictionListQuery();
  const { data: localesObject } = useGetLocalesQuery({ locale, draft });
  const { data } = useGetPageQuery({ id, draft, locale });

  const locales = localesObject?.docs;
  const jurisdictionList = jurisdictionListObject?.docs;
  const defaultJurisdiction = jurisdictionList?.[0];

  const currentJurisdiction =
    jurisdictionList?.filter(j => j.slug === jurisdiction)?.[0] ??
    defaultJurisdiction;

  const page = data as Page;

  const sectionsGroup = page?.parts?.sections;
  const jurSectionsGroup = sectionsGroup?.jurisdictions?.find(
    item =>
      (typeof item.jurisdiction !== 'string' &&
        item.jurisdiction.slug === jurisdiction) ||
      item.jurisdiction === currentJurisdiction?.id,
  );

  const hero = isPopulatedReference(page?.parts?.hero)
    ? page?.parts?.hero
    : undefined;

  const isPageCanonical = jurSectionsGroup?.isPageCanonical;
  const sections = ((jurSectionsGroup?.sections ??
    sectionsGroup?.defaultSections) ||
    []) as Section[];
  const isShowBreadcrumbs = !page?.isBreadcrumbsHidden && !hero;

  return (
    <PageLayout
      locale={locale}
      draft={draft}
      meta={{
        ...page?.meta,
        isPageCanonical,
        url: page?.url,
      }}
      breadcrumbs={isShowBreadcrumbs ? page?.breadcrumbs : []}
    >
      {hero && <HeroConnected hero={hero} locales={locales} />}
      {sections?.map(section => (
        <SectionParser
          key={section.id}
          section={section}
          locales={locales}
          jurisdictionList={jurisdictionList}
          jurisdictionItem={currentJurisdiction}
        />
      ))}
    </PageLayout>
  );
};

export {
  getStaticPaths,
  getStaticProps,
} from '@front/master/components/pages/SectionsPage';

export default SectionsPage;
