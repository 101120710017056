const BASE_URL = process.env.NEXT_SERVER_BASE_URL;

export const registerMetrics = ({
  duration,
  route,
  usedMemory,
}: {
  duration: number;
  usedMemory: number;
  route: string;
}): Promise<Response> => {
  return fetch(`${BASE_URL}/api/register-ssr-metrics`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      duration,
      usedMemory,
      route,
    }),
  });
};
